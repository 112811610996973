<template>
  <div>
    <custom-toolbar
      :title="'Détails du trajet'"
      @refresh="refreshObjet"
    />
    <v-row class="mt-1">
      <v-col
        cols="12"
        lg="4"
        md="5"
      >
        <v-skeleton-loader
          v-show="isDialogVisible"
          boilerplate
          class="mx-auto"
          elevation="2"
          max-width="360"
          type="card-avatar, article, actions, card-avatar, article, actions"
        ></v-skeleton-loader>
        <road-panel
          v-show="!isDialogVisible"
          :road="road"
        />
      </v-col>
      <v-col
        cols="12"
        lg="8"
        md="8"
      >
        <v-skeleton-loader
          v-show="isDialogVisible"
          boilerplate
          class="mx-auto"
          elevation="2"
          max-width="840"
          type="card-avatar, article, article, actions"
        ></v-skeleton-loader>
        <v-tabs
          v-show="!isDialogVisible"
          v-model="userTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-show="!isDialogVisible"
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <road-stop-points />
          </v-tab-item>
          <v-tab-item>
            <road-payment :road="road" />
          </v-tab-item>
          <v-tab-item>
          </v-tab-item>
        </v-tabs-items>
        <v-row>
          <v-col
            cols="12"
            lg="12"
            md="12"
          >
            <road-map
              v-show="!isDialogVisible"
              :road="$store.state.road.road"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getCurrentInstance, onMounted, ref } from '@vue/composition-api'
import {
  mdiAccount,
  mdiCar,
  mdiCarArrowLeft, mdiCarDoor,
  mdiCarEstate, mdiCarSettings,
  mdiCashMarker,
  mdiCashMultiple,
  mdiEmail, mdiFileDocument,
  mdiMapMarkerDistance,
  mdiPhone,
  mdiRefresh,
  mdiArrowLeftBoldCircleOutline,
} from '@mdi/js'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import store from '@/store'
import router from '@/router'
// eslint-disable-next-line import/extensions
import RoadPanel from '@/components/road/RoadPanel'
import RoadStopPoints from '@/components/road/RoadStopPoints'
import RoadMap from '@/components/road/RoadMap'
import RoadPayment from '@/components/road/RoadPayment'
import CustomToolbar from "@core/components/toolbar/CustomToolbar";
import {watch} from "@vue/composition-api/dist/vue-composition-api";

export default {
  name: 'RoadDetails',
  components: {
    CustomToolbar,
    RoadPayment,
    RoadMap,
    RoadStopPoints,
    RoadPanel,
    LoaderDialog,
  },
  computed: {
    ...mapState('road', ['road']),
  },

  setup() {
    const isDialogVisible = ref(false)
    const userTab = ref(null)
    // eslint-disable-next-line no-shadow,no-use-before-define
    const route = getCurrentInstance().proxy.$route

    const getUser = () => {
      const id = route.params.id !== undefined ? route.params.id : null
      if (id !== null) {
        store.state.destination.field.road_id = id
        if (store.state.road.road === null || store.state.road.road.id !== id) {
          isDialogVisible.value = true
          store.dispatch('road/getRoadById', id).then(() => {
            isDialogVisible.value = false
          }).catch(error => {
            console.log(error)
          })
        }
      }
    }

    const refreshObjet = () => {
      const id = route.params.id !== undefined ? route.params.id : null
      if (id !== null) {
        isDialogVisible.value = true
        store.dispatch('road/getRoadById', store.state.road.road.id).then(() => {
          isDialogVisible.value = false
        }).catch(error => {
          console.log(error)
          router.back()
        })
      }
    }

    onMounted(getUser)

    const prev = () => {
      router.back()
    }

    const tabs = [
      { icon: mdiMapMarkerDistance, title: 'Arrêts' },
      { icon: mdiCashMultiple, title: 'Paiements' },
    ]

    watch(() => store.state.road.refreshRoadCount, () => {
      isDialogVisible.value = true
      store.dispatch('road/getRoadById', store.state.road.refreshRoadId).then(() => {
        isDialogVisible.value = false
      }).catch(error => {
        console.log(error)
      })
    }, { deep: true })

    return {
      isDialogVisible,
      getUser,
      prev,
      refreshObjet,
      tabs,
      userTab,
      icons: {
        mdiRefresh,
        mdiArrowLeftBoldCircleOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
